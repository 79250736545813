import BaslacArea from './baslacPage.style';
import React from 'react';
import Image from 'common/components/Image';
import Box from 'common/components/Box';
import Container from 'common/components/UI/Container';
import BaslacImage from 'common/assets/image/agency/baslac.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import GatsbyImage from 'common/components/GatsbyImage';
import { openModal, closeModal } from '@redq/reuse-modal';
import Button from 'common/components/Button';
import { graphql, useStaticQuery } from "gatsby";

const IntroVideo = () => (
    <iframe
        title="Lanzamiento Baslac"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/-MSzXTRw3A0?controls=0&showinfo=0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
);

const CloseModalButton = () => (
    <Button
        className="modalCloseBtn"
        variant="fab"
        onClick={() => closeModal()}
        icon={<i className="flaticon-plus-symbol" />}
    />
);

const BaslacPage = ({
    row,
    buttonStyle,

 }) => {

    // Video modal handler
    const handleVideoModal = () => {
        openModal({
            config: {
                className: 'video-modal',
                disableDragging: true,
                default: {
                    width: '100%',
                    height: '100%',
                    x: 0,
                    y: 0,
                },
            },
            component: IntroVideo,
            componentProps: {},
            closeComponent: CloseModalButton,
            closeOnClickOutside: false,
        });
    };

    const Data = useStaticQuery(graphql`
        query {
          featureImage: file(
            relativePath: { eq: "image/agency/preview-image-baslac.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
    `);

    return (
        <BaslacArea id="BaslacSection">
            <Container>
                <Box className="row" {...row}>
                    <h1>BASLAC: Calidad y rentabilidad en Repintado</h1>
                    <h3>Descubre Baslac, una solución innovadora en repintado de automoción, perteneciente a BASF,
                        que combina eficiencia, facilidad de uso y calidad superior. Desde su inicio en 2006,
                        Baslac se ha establecido como una marca confiable en más de 50 países, ofreciendo una amplia gama de productos y un soporte online excepcional
                    </h3>
                    <Image className="patternImage image-header" src={BaslacImage} alt="Marca Baslac" />
                </Box>
                {/* Sección de Ventajas */}
                <Box className="row" {...row}>
                    <h2>Ventajas de Baslac</h2>
                    <ul className="advantages">
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Relación Calidad-Precio Insuperable:</strong> Baslac se distingue por ofrecer una excelente calidad a precios accesibles, convirtiéndose en una opción ideal para quienes buscan el mejor "value for money" en el repintado de automoción.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Competencia en Color:</strong> Resultados precisos y constantes con nuestro sistema avanzado de color.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Calidad y Confiabilidad:</strong> Productos de alta calidad para todo tipo de reparaciones automotrices.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Soporte Online y Formación:</strong> Acceso a formación profesional y soporte técnico a través de nuestras plataformas digitales.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Amplia Gama de Productos:</strong> Desde esmaltes y lacas hasta diluyentes y aditivos, Baslac ofrece una solución completa para tus necesidades de repintado.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Facilidad de Uso y Eficiencia:</strong> Productos diseñados para un uso sencillo y eficiente, asegurando un secado rápido y un excelente acabado.</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Presencia Global y Experiencia:</strong> Con una presencia consolidada en más de 50 países, Baslac es sinónimo de experiencia y confianza en el sector del repintado de automoción.</li>
                    </ul>
                </Box>
                <Box className="row mt-20" {...row}>
                    <h2>Descubre la mejor elección para el repintado de automoción</h2>
                </Box>
                <Box className="row figure" {...row}>
                    <GatsbyImage
                        src={
                            (Data.featureImage !== null) | undefined
                                ? Data.featureImage.childImageSharp.gatsbyImageData
                                : {}
                        }
                        alt="Video Preview Image"
                    />
                    <Box className="fig__caption">
                        <Button
                            {...buttonStyle}
                            icon={<i className="flaticon-youtube" />}
                            onClick={handleVideoModal}
                            aria-label="play"
                        />
                    </Box>
                </Box>
            </Container>
        </BaslacArea>
  );
};

// VideoSection default style
BaslacPage.defaultProps = {
    // button default design
    buttonStyle: {
        variant: 'textButton',
        p: 0,
        color: '#ec4444',
        fontSize: '71px',
    },
    imageWrapper: {
        display: ' block',
    },
};

export default BaslacPage;
